<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-row>
      <b-col
        md="6"
        lg="4"
      >
        <b-card
          title="Logo & Icon"
          class="text-center"
        >
          <b-card-text>
            {{ $t('Edit logos and icons') }}
          </b-card-text>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="openLogoSetting"
          >
            {{ $t('Edit') }}
          </b-button>
        </b-card>
      </b-col>
      <b-col
        md="6"
        lg="4"
      >
        <b-card
          title="Graphics Store"
          class="text-center"
        >
          <b-card-text>
            {{ $t('Edit Graphics Store') }}

          </b-card-text>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="openGraphicsScreen"
          >
            {{ $t('Edit') }}
          </b-button>
        </b-card>
      </b-col>
      <b-col
        md="6"
        lg="4"
      >
        <b-card
          title="Splash Screen"
          class="text-center"
        >
          <b-card-text>
            {{ $t('Edit Splash Screen') }}

          </b-card-text>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="openSplashScreen"
          >
            {{ $t('Edit') }}
          </b-button>
        </b-card>
      </b-col>
      <b-col
        md="6"
        lg="4"
      >
        <b-card
          title="Slide Screen"
          class="text-center"
        >
          <b-card-text>
            {{ $t('Edit Slide Screen') }}
          </b-card-text>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="openSlideScreen"
          >
            {{ $t('Edit') }}
          </b-button>
        </b-card>
      </b-col>
      <b-col
        md="6"
        lg="4"
      >
        <b-card
          title="Background Screen"
          class="text-center"
        >
          <b-card-text>
            {{ $t('Edit Background Screen') }}
          </b-card-text>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="openBackgroundScreen"
          >
            {{ $t('Edit') }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow, BCol, BCard, BCardText, BButton,
  },
  directives: {
    Ripple,
  },
  methods: {
    openLogoSetting() {
      this.$router.push({ path: '/e-commerce/images/settings/logo' })
    },
    openSplashScreen() {
      this.$router.push({ path: '/e-commerce/images/settings/splash' })
    },
    openGraphicsScreen() {
      this.$router.push({ path: '/e-commerce/images/settings/graphics/store' })
    },
    openSlideScreen() {
      this.$router.push({ path: '/e-commerce/images/settings/slide' })
    },
    openBackgroundScreen() {
      this.$router.push({ path: '/e-commerce/images/settings/background' })
    },
  },
}
</script>
